import React, { useEffect, useState } from "react";

import {
  Button,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { MoreVert } from "@material-ui/icons";
import ArrowDown from "@material-ui/icons/ArrowDropDown";
import ArrowUp from "@material-ui/icons/ArrowDropUp";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import { useHistory } from "react-router-dom";

import WhatsAppBussnessIcon from "../../assets/WhatsAppBusinessIconWithout.png";
import DeleteAllTicketModal from "../../components/DeleteAllTicketModal";
import ExportTicketsModal from "../../components/ExportTicketsModal";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import { TicketLogFilters } from "../../components/TicketLogFilters";
import Title from "../../components/Title";
import TransferAllTicketModal from "../../components/TransferAllTicketModal";
import { useAuthContext } from '../../context/Auth/AuthContext';
import toastError from "../../errors/toastError";
import api from "../../services/api";
import TicketInfos from "./TicketInfos";

const statusPT = {
  open: "Aberto",
  closed: "Fechado",
  pending: "Aguardando",
  groups: "Grupo",
};

const INITIAL_FILTERS = {extraFields: {}}

const EndTickets = () => {
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [ticketsList, setTicketsList] = useState([]);
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const [deleteAllTicketModalOpen, setDeleteAllTicketModalOpen] =
    useState(false);
  const [transferAllTicketModalOpen, setTransferAllTicketModalOpen] =
    useState(false);
  const [exportTicketsModalOpen, setExportTicketsModalOpen] = useState(false);
  const { user } = useAuthContext();
  const [filters, setFilters] = useState(INITIAL_FILTERS);
  const [selectedTicket, setSelectedTicket] = useState(null);
	const [lastSanitizedFilters, setLastSanitizedFilters] = useState(INITIAL_FILTERS);
	const [countTickets, setCountTickets] = useState(0);
	const [showFilters, setShowFilters] = useState(false);
	const [menuOpen, setMenuOpen] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);

	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  useEffect(() => {
    if (pageNumber === 1) return;

    const sanitizedFilters = {
      email: filters.email,
      name: filters?.name,
      number: filters.number,
      isGroup: filters.isGroup,
      userIds: filters.user,
      channels: filters.channel,
      extraFields: Object.entries(filters.extraFields).map(([key, value]) => {
        if (value !== "" && value !== null) {
          return {
            fieldName: key,
            fieldValue: value,
          };
        }
        return null;
      }),
      createdAtStart: filters.createdAtStart,
      createdAtEnd: filters.createdAtEnd,
      closedAtStart: filters.closedAtStart,
      closedAtEnd: filters.closedAtEnd,
      motives: filters.motive,
      queueIds: filters.queue,
      status: filters.status,
      tags: filters.tag,
      origins: filters.origin,
      protocol: filters.protocol,
    };

    const fetchMoreTickets = async () => {
      try {
        const { data } = await api.post("/tickets/filtered-tickets", {
          ...sanitizedFilters,
          pageNumber,
        });

        setTicketsList((prevList) => [...prevList, ...data.tickets]);
				setCountTickets(data.count);
        setHasMore(data.hasMore);
        setLoading(false);
      } catch (err) {
        toastError(err);
      }
    };

    fetchMoreTickets();
  }, [pageNumber, filters]);

  const resetData = () => {
    setTicketsList([]);
    fetchTickets();
  };

  const goToTicket = (ticketId) => {
    history.push(`tickets/${ticketId}`);
  };

  const handleOpenDeleteAllModal = (e) => {
    setDeleteAllTicketModalOpen(true);
  };

  const handleCloseDeleteAllTicketModal = () => {
    setDeleteAllTicketModalOpen(false);
  };

  const handleOpenTransferAllTicketModal = (e) => {
    setTransferAllTicketModalOpen(true);
  };

  const handleCloseTransferAllTicketModal = () => {
    setTransferAllTicketModalOpen(false);
  };

  const loadMore = () => {
    setPageNumber((prevState) => prevState + 1);
  };

  const handleScroll = (e) => {
    if (!hasMore || loading) return;
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore();
    }
  };

  const fetchTickets = async (params) => {
    try {
      const { data } = await api.post("/tickets/filtered-tickets/", {
        ...params,
      });
      setTicketsList(data.tickets);
			setCountTickets(data.count);
      setPageNumber(1);
      setHasMore(data.hasMore);
      setLoading(false);
			params ? setLastSanitizedFilters(params) : setLastSanitizedFilters(INITIAL_FILTERS);
    } catch (err) {
      toastError(err);
    }
  };

  useEffect(() => {
    setLoading(true);

    fetchTickets();
  }, [user]);

  const options = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  };

  const defineOrigim = (ticket) => {
    if (!ticket?.action || ticket?.action.length === 0) {
      return "";
    }

    if (
      ticket.action &&
      ticket.action.find(
        (action) => action.fromUser === null || action.fromUser === "Chatbot"
      )
    ) {
      return "Potencial";
    } else {
      return ticket.origin || "Ativo";
    }
  };

	const renderFilters = () => {
		if (!isMobile) return (
      <TicketLogFilters
        filters={filters}
        setFilters={setFilters}
        fetchTickets={fetchTickets}
      />
		);

		if (isMobile) return (
			<>
				<div style={{ display: "flex", justifyContent: "center", marginBottom: "10px" }}>
					<Button
						variant="contained"
						color="warning"
						onClick={() => setShowFilters(!showFilters)}
					>
						Filtros {showFilters ? (
							<ArrowUp style={{ color: "black" }} />
						) : (
							<ArrowDown style={{ color: "black" }} />
						)}
					</Button>
					{renderMainHeaderButtons()}
				</div>

				{showFilters && (
					<TicketLogFilters
						filters={filters}
						setFilters={setFilters}
						fetchTickets={fetchTickets}
					/>
				)}
			</>
		)
	}

	const renderMainHeaderButtons = () => {
		if (!isMobile) {
			return (
				<MainHeaderButtonsWrapper>
          {
            user.profile === 'admin' &&
            <Button
              variant="contained"
              color="primary"
              onClick={() => setExportTicketsModalOpen(true)}
            >
              Exportar Tickets
            </Button>
          }
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenDeleteAllModal}
          >
            Finalizar Tickets
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenTransferAllTicketModal}
          >
            Transferir tickets
          </Button>
        </MainHeaderButtonsWrapper>
			)
		} else {
			return (
				<>
					<IconButton
						aria-controls="menu-appbar"
						aria-haspopup="true"
						onClick={(e) => {
							setMenuOpen(true);
							setAnchorEl(e.currentTarget);
						}}
						color="inherit"
					>
						<span style={{fontSize: '16px'}}>Opções</span>
						<MoreVert />
					</IconButton>

					<Menu
						id="menu-appbar"
						anchorEl={anchorEl}
						getContentAnchorEl={null}
						anchorOrigin={{
							vertical: "bottom",
							horizontal: "right",
						}}
						keepMounted
						transformOrigin={{
							vertical: "top",
							horizontal: "right",
						}}
						open={menuOpen}
						onClose={() => setMenuOpen(false)}
					>
            {user.profile === 'admin' &&
              <MenuItem
                onClick={() => {
                  setExportTicketsModalOpen(true);
                  setMenuOpen(false);
                }}
              >
                Exportar Tickets
              </MenuItem>
            }
						<MenuItem
							onClick={() => {
								handleOpenDeleteAllModal();
								setMenuOpen(false);
							}}
						>
							Finalizar Tickets
						</MenuItem>
						<MenuItem
							onClick={() => {
								handleOpenTransferAllTicketModal();
								setMenuOpen(false);
							}}
						>
							Transferir tickets
						</MenuItem>
					</Menu>
			</>
			)
		}
	}

  return (
    <MainContainer>
      <ExportTicketsModal
        open={exportTicketsModalOpen}
        onClose={() => setExportTicketsModalOpen(false)}
				lastSanitizedFilters={lastSanitizedFilters}
      />
      <DeleteAllTicketModal
        modalOpen={deleteAllTicketModalOpen}
        onClose={handleCloseDeleteAllTicketModal}
        onClick={resetData}
      />
      <TransferAllTicketModal
        modalOpen={transferAllTicketModalOpen}
        onClose={handleCloseTransferAllTicketModal}
      />
      <MainHeader>
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						flexDirection: 'column',
						alignItems: 'center'
					}}
				>
        <Title>Relatório de Tickets</Title>
				<Title>Total: {countTickets}</Title>
				</div>
				{isMobile ? renderFilters() : renderMainHeaderButtons()}
			</MainHeader>

			{!isMobile && renderFilters()}

      <TableContainer
        style={{ height: isMobile ? "" : "57vh", overflow: "scroll" }}
        component={Paper}
        onScroll={handleScroll}
      >
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="left">Visualizar</TableCell>
              <TableCell align="left">Protocolo</TableCell>
              <TableCell align="left">Status</TableCell>
              <TableCell align="left">Cliente</TableCell>
              <TableCell align="left">Atendente</TableCell>
              <TableCell align="left">Departamento</TableCell>
              <TableCell align="left">Data de criação</TableCell>
              <TableCell align="left">Data de finalização</TableCell>
              <TableCell align="left">Motivo de Finalização</TableCell>
              <TableCell align="left">Avaliação</TableCell>
              <TableCell align="left">Origem</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <>
              {ticketsList?.length > 0 &&
                ticketsList.map((ticket, i) => (
                  <>
                    <TableRow
                      style={{
                        cursor: "pointer",
                      }}
                      key={`row-${i}`}
                      onClick={() => {
                        selectedTicket?.id === ticket.id
                          ? setSelectedTicket(null)
                          : setSelectedTicket(ticket);
                      }}
                    >
                      <TableCell align="left">
                        <IconButton
                          size="small"
                          onClick={() => goToTicket(ticket.id)}
                        >
                          {(!ticket.contact?.channel ||
                            ticket.contact?.channel === "whatsapp") && (
                            <WhatsAppIcon />
                          )}
													{(ticket.contact?.channel === 'whatsapp_official') && (
                            <img
															src={WhatsAppBussnessIcon}
															style={{width: '22px', height: '22px'}}
															alt=""
														/>
                          )}
                          {ticket.contact?.channel === "facebook" && (
                            <FacebookIcon />
                          )}
                          {ticket.contact?.channel === "instagram" && (
                            <InstagramIcon />
                          )}
                        </IconButton>
                      </TableCell>
                      <TableCell align="left">{ticket.id}</TableCell>
                      <TableCell align="left">
                        {statusPT[ticket.status]}
                      </TableCell>

                      <TableCell align="left">{ticket.contact?.name}</TableCell>
                      <TableCell align="left">{ticket.user?.name}</TableCell>
                      <TableCell align="left">{ticket.queue?.name}</TableCell>

                      <TableCell align="left">
                        {new Date(ticket.createdAt).toLocaleDateString(
                          "pt-BR",
                          options
                        )}
                      </TableCell>
                      <TableCell align="left">
                        {ticket.status === "closed" &&
                          new Date(ticket.closedAt).toLocaleDateString(
                            "pt-BR",
                            options
                          )}
                      </TableCell>
                      <TableCell align="left">
                        {ticket.endTicket && ticket.endTicket[0]?.option}
                      </TableCell>
                      <TableCell align="left">
                        {ticket.avaliation?.avaliation}
                      </TableCell>
                      <TableCell align="left">
                        {ticket.origin || defineOrigim(ticket)}
                      </TableCell>
                    </TableRow>

                    {selectedTicket?.id === ticket.id && (
                      <TicketInfos ticketInfos={ticket} />
                    )}
                  </>
                ))}
              {loading && <TableRowSkeleton avatar columns={2} />}
            </>
          </TableBody>
        </Table>
      </TableContainer>
    </MainContainer>
  );
};

export default EndTickets;
